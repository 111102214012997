import { useCallback } from 'react';
import appConfig from '@nerdwallet/app-config';

import useAnalyticsContext from './useAnalyticsContext';
import { getCookie } from '../../auth/utilities/cookies';
import { SegmentTrackingAttributes } from '../../auth/lib/helpers';

export type DefaultIdentifyPayload = {
  /* eslint-disable-next-line camelcase */
  member_identity_id: string;
  /* eslint-disable-next-line camelcase */
  most_recent_traditional_device_id: string;
  /* eslint-disable-next-line camelcase */
  is_pwru: boolean;
  /* eslint-disable-next-line camelcase */
  deployable_name: string;
  /* eslint-disable-next-line camelcase */
  deployable_version: string;
  /* eslint-disable-next-line camelcase */
  authentication_provider?: string;
  /* eslint-disable-next-line camelcase */
  identify_call_type: string;
};

export type RegisterIdentifyPayload = DefaultIdentifyPayload & {
  /* eslint-disable-next-line camelcase */
  pwru_registered_at: string;
  /* eslint-disable-next-line camelcase */
  pwru_registration_driver_page_url: string;
  /* eslint-disable-next-line camelcase */
  pwru_registration_driver_page_name: string;
  /* eslint-disable-next-line camelcase */
  pwru_registration_driver_location?: string;
  /* eslint-disable-next-line camelcase */
  pwru_registration_driver_sub_location?: string;
  /* eslint-disable-next-line camelcase */
  pwru_registration_driver_element?: string;
  /* eslint-disable-next-line camelcase */
  authentication_provider?: string;
  /* eslint-disable-next-line camelcase */
  identify_call_type: string;
  /* eslint-disable-next-line camelcase */
  registration_driver_page_view_id: string;
  /* eslint-disable-next-line camelcase */
  registration_driver_page_content_id?: string;
  /* eslint-disable-next-line camelcase */
  registration_driver_page_content_source?: string;
  /* eslint-disable-next-line camelcase */
  registration_driver_page_flow_name?: string;
};

const commonProps = {
  brand: 'Nerdwallet',
  // eslint-disable-next-line no-underscore-dangle
  deployable_name: appConfig?._meta?.deployable,
  // eslint-disable-next-line no-underscore-dangle
  deployable_version: appConfig?._meta?.version,
};

export const getIdentifyPayload = (
  identityId: string,
  authType: string,
  segmentTrackingAttributes: SegmentTrackingAttributes
): DefaultIdentifyPayload | RegisterIdentifyPayload => {
  const defaultPayload: DefaultIdentifyPayload = {
    member_identity_id: identityId,
    most_recent_traditional_device_id: getCookie('cookie_id'),
    is_pwru: true,
    deployable_name: segmentTrackingAttributes?.deployableName,
    deployable_version: segmentTrackingAttributes?.deployableVersion,
    identify_call_type: 'pwru_login',
  };

  const registrationIdentityPayload: RegisterIdentifyPayload = {
    ...defaultPayload,
    pwru_registered_at: new Date().toISOString(),
    pwru_registration_driver_page_url: window.location.href,
    pwru_registration_driver_page_name:
      segmentTrackingAttributes?.driverPageName,
    pwru_registration_driver_location:
      segmentTrackingAttributes?.driverLocation,
    pwru_registration_driver_sub_location:
      segmentTrackingAttributes?.driverSubLocation,
    pwru_registration_driver_element: segmentTrackingAttributes?.driverElement,
    authentication_provider: segmentTrackingAttributes?.authProvider,
    identify_call_type: 'pwru_registration',
    registration_driver_page_view_id:
      segmentTrackingAttributes?.driverPageViewId,
    registration_driver_page_content_id:
      segmentTrackingAttributes?.driverContentId,
    registration_driver_page_content_source:
      segmentTrackingAttributes?.driverContentSource,
    registration_driver_page_flow_name:
      segmentTrackingAttributes?.driverFlowName,
  };

  if (authType === 'register') {
    return registrationIdentityPayload;
  }
  return defaultPayload;
};

const useAnalytics = () => {
  const { analytics } = useAnalyticsContext();

  const track = useCallback(
    async (eventName: string, eventProps: Record<string, any> = {}) => {
      await analytics?.track(eventName, {
        ...commonProps,
        ...eventProps,
      });
    },
    [analytics]
  );

  /**
   * Implement clearUncacheableTraits method
   * To avoid login and other non-registration events from capturing registration information
   * https://nerdwallet.atlassian.net/browse/REG-2598
   * https://nerdwallet.atlassian.net/wiki/spaces/PEDD/pages/2614034657/Standard+Event+Identification+Conventions#2.-Registration%2C-Email-Address-Change%2C-Login%2C-and-Logout-%5B2023.06.06%5D
   */
  const clearUncacheableTraits = useCallback(async () => {
    const user = await analytics?.user();
    if (!user) return;
    const traits = user.traits();
    delete traits.email;
    delete traits.identify_call_type;
    delete traits.registration_driver_page_view_id;
    delete traits.registration_driver_page_flow_name;
    delete traits.registration_driver_page_content_id;
    delete traits.registration_driver_page_content_source;
    delete traits.pwru_registered_at;
    delete traits.pwru_registration_driver_page_url;
    delete traits.pwru_registration_driver_page_name;
    delete traits.pwru_registration_driver_location;
    delete traits.pwru_registration_driver_sub_location;
    delete traits.pwru_registration_driver_element;
    user.traits(traits);
  }, [analytics]);

  const getAnonymousId = async () => {
    const user = await analytics?.user();
    if (!user) return;
    return user.anonymousId();
  };

  const identify = useCallback(
    async (userId: string, traits: Record<string, any> = {}) => {
      const additionalTraits = {
        ...traits,
      };

      await analytics?.identify(userId, additionalTraits);
    },
    [analytics]
  );

  return {
    getAnonymousId,
    track,
    identify,
    clearUncacheableTraits,
  };
};

export default useAnalytics;
