import { AnalyticsContext, AnalyticsContextProps } from './AnalyticsContext';
import { $segment } from '../../store/client';

interface AnalyticsProviderProps {
  children: JSX.Element;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const segment = $segment.get();

  return (
    <AnalyticsContext.Provider value={segment}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
